<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <footer class="bg-white">
    <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8 border-t border-indigo-500 border-opacity-25">
      <div class="mt-8 md:mt-0">
        <p class="text-right text-base text-gray-400" id="footerelement-p-copyright">&copy; 2022-2023 Julien de Charentenay</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterElement",
};
</script>
