<template>
  <ModalDialog>
        <div>
          <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-200">
            <!-- Spinning -->
            <svg class="animate-spin h-8 w-8 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
               <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
               <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
          <div class="mt-3 text-center sm:mt-5">
            <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">Operation in progress, please be patient...</h3>
            <div class="mt-2">
              <p class="text-sm text-gray-500" id="loadingalert-p-message">{{ message }} </p>
            </div>
          </div>
        </div>
  </ModalDialog>
</template>
<script>
import ModalDialog from "@/components/ModalDialog.vue";

export default {
  name: "LoadingAlert",
  components: { ModalDialog },
  props: [ "message" ],
};
</script>
